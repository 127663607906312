
.loginButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Loader animation */
  .loader {
    border: 3px solid rgba(255, 255, 255, 0.3); /* Light border for animation */
    border-radius: 50%;
    border-top: 3px solid #ffffff; /* White color for the top border */
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite; /* Spin animation */
  }
  
  /* Keyframes for spinning animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  