@keyframes dotMove {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .dotAnimation {
    display: inline-block;
    margin-right: 4px;
    animation: dotMove 1.2s infinite;
  }
  
  .dotAnimation:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dotAnimation:nth-child(3) {
    animation-delay: 0.4s;
  }
  