/* BubbleEffect.css */
@keyframes bubble-pulse {
    0% {
      transform: scale(1);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  
  .bubble-effect {
    background: rgba(255, 255, 255, 0.3); /* Bubble color */
    border-radius: 50%;
    animation: bubble-pulse 1s ease-out infinite;
    z-index: -1;
    padding: 15px;
    inset: -15px;
  }
  