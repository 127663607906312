/* styles.css */
@keyframes glow {
    0%, 100% {
      color: #FDDC5C; /* Original golden color */
      text-shadow: 0 0 5px #FDDC5C, 0 0 10px #FDDC5C, 0 0 15px #FDDC5C, 0 0 20px #FDDC5C, 0 0 25px #FDDC5C;
    }
    50% {
      color: #FFF6B3; /* Much lighter golden color */
      text-shadow: 0 0 10px #FFF6B3, 0 0 20px #FFF6B3, 0 0 30px #FFF6B3, 0 0 40px #FFF6B3, 0 0 50px #FFF6B3;
    }
  }
  
  .glow {
    animation: glow 2s infinite;
  }
  